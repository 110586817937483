<template>
  <v-container fluid class="pl-12 pr-12">
    <v-layout column wrap>
      <v-row>
        <v-col>
          <p class="font-weight-black pl-3" style="font-size:32px">
            {{ $t('title.agency.agencyList') }}
          </p>
        </v-col>
      </v-row>
      <v-row>
        <p class="pl-5" style="word-wrap: break-all; max-width:90vw">
          {{ $t('description.agencyList.explanation1') }}
        </p>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isAgencyRegisterButtonEnabled"
                    :disabled="!isAgencyRegisterButtonEnabled"
                    @click="onClickAgencyRegisterButton()"
                    >{{ $t('button.agencyRegister') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.agencyList.agencyRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    dark
                    slot="activator"
                    @click="onClickAgencySearchButton()"
                    >{{ $t('button.agencySearch') }}</v-btn
                  >
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.agencyList.agencySearch') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isAgencyBulkRegisterButtonEnabled"
                    :disabled="!isAgencyBulkRegisterButtonEnabled"
                    @click="onClickAgencyBulkRegisterButton()"
                    >{{ $t('button.agencyBulkRegister') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.agencyList.agencyBulkRegister') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        <v-col>
          <v-card outlined height="auto">
            <v-container>
              <v-row>
                <v-col class="py-0" style="max-width:315px">
                  <v-btn
                    class="font-weight-black mx-auto"
                    align="center"
                    width="100%"
                    height="96px"
                    max-width="300px"
                    color="next"
                    style="font-size:20px"
                    :dark="isAgencyBulkEditButtonEnabled"
                    :disabled="!isAgencyBulkEditButtonEnabled"
                    @click="onClickAgencyBulkUpdateButton()"
                    >{{ $t('button.agencyBulkUpdate') }}
                  </v-btn>
                </v-col>
                <v-col class="py-0">
                  <p class="pl-3 mb-0">
                    {{ $t('description.agencyList.agencyBulkUpdate') }}
                  </p>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
      <v-row>
        <p class="font-weight-black px-4 pt-4 mb-1" style="font-size:24px">
          {{ $t('label.agencyList') }}
        </p>
      </v-row>
      <v-data-table
        calculate-widths
        class="elevation-1"
        :headers="headers"
        :items="records"
        :options.sync="options"
        :server-items-length="total"
        :loading="isLoading"
        :loading-text="$t('description.agencyReading')"
        :no-data-text="$t('error.agencyListNotFound')"
        :footer-props="{
          itemsPerPageText: '1ページあたりの行数',
          itemsPerPageOptions: itemsPerPageOptions,
        }"
      >
        <template v-slot:item.detail="{ item }" class="pa-0">
          <router-link
            :to="{
              name: 'AgencyDetail',
              params: { agency_id: item.id },
            }"
            target="_blank"
            class="router-link-transparent"
            style="text-decoration:none"
          >
            <template>
              <v-chip
                class="font-weight-black float-center"
                color="next"
                dark
                ripple
                link
                >{{ $t('header.agencyListTable.detail') }}</v-chip
              >
            </template>
          </router-link>
        </template>
      </v-data-table>
      <AgencySearchDialog
        @onSuccess="onSuccessSearchAgency"
        @onClear="onClearAgencySearchCondition"
        ref="agencySearchDialog"
      ></AgencySearchDialog>
      <!-- 代理店登録ダイアログ -->
      <FormsDialog
        :showDialog="showCreateAgencyDialog"
        :title="$t('title.agency.agencyRegister')"
        :text="null"
        :subText="null"
        :negativeButtonTitle="$t('button.cancel')"
        :positiveButtonTitle="$t('button.register')"
        :onClickNegativeButton="cancelCreateAgency"
        :onClickPositiveButton="createAgency"
        :targets="createAgencyTargets"
      />
      <AgencyCsvFileSelectDialog
        @onSuccess="onSuccessCsvImport"
        ref="csvFileSelectDialog"
        :salesCodeDetails="salesCodeDetails"
        :agencyCodeList="agencyCodeList"
        :isRegister="isRegister"
      />
      <ValidateErrorDialog ref="validateErrorDialog" />
      <BulkRegisterListDialog
        ref="bulkRegisterListDialog"
        @onSuccess="onBulkRegisterAgency"
      />
      <BulkUpdateListDialog
        ref="bulkUpdateListDialog"
        @onSuccess="onBulkUpdateAgency"
      />
      <CompletedDialog ref="completedDialog"></CompletedDialog>
      <ErrorDialog ref="errorDialog"></ErrorDialog>
    </v-layout>
  </v-container>
</template>
<script>
import { mapGetters, mapActions } from 'vuex';
import {
  AgencyListTableHeader,
  AgencyConfirmTableHeader,
  agencyListDisplayDefault,
  ErrorCode,
  MaxLength,
} from '@/lib/const';
import { Role } from '@/lib/role';
import CompletedDialog from '@/components/organisms/agency/CompletedDialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import FormsDialog from '@/components/organisms/agency/FormsDialog';
import AgencySearchDialog from '@/components/organisms/agency/AgencySearchDialog';
import {
  getAgencies,
  getAgencyCodeList,
  getUpdateAgencyList,
  postAgency,
  postBulkRegisterAgency,
  putBulkUpdateAgency,
} from '@/apis/agency/agencies';
import { getSalesCodeList, getSalesCodeDetails } from '@/apis/agency/sales';
import AgencyCsvFileSelectDialog from '@/components/organisms/agency/AgencyCsvFileSelectDialog';
import ValidateErrorDialog from '@/components/organisms/agency/ValidateErrorDialog';
import BulkRegisterListDialog from '@/components/organisms/agency/BulkRegisterListDialog';
import BulkUpdateListDialog from '@/components/organisms/agency/BulkUpdateListDialog';

export default {
  name: 'AgencyList',
  components: {
    CompletedDialog,
    ErrorDialog,
    FormsDialog,
    AgencySearchDialog,
    AgencyCsvFileSelectDialog,
    ValidateErrorDialog,
    BulkRegisterListDialog,
    BulkUpdateListDialog,
  },

  data: vm => ({
    headers: AgencyListTableHeader,
    records: [],

    // 案件一覧取得オプション
    options: {
      page: agencyListDisplayDefault.page,
      itemsPerPage: agencyListDisplayDefault.itemsPerPage,
      sortBy: [],
      sortDesc: [],
    },

    // ソートキー名のマッピング
    sortKey: {
      agencyCode: 'agencyCode',
    },

    // 代理店検索条件
    agencySearchCondition: {},

    // 1ページあたりの表示件数の選択肢
    itemsPerPageOptions: agencyListDisplayDefault.itemsPerPageOptions,

    // 案件一覧取得件数
    total: 0,

    // 代理店登録データ (デフォルト)
    createAgencyDefaultTargets: [
      {
        value: null,
        targetName: 'agencyCode',
        type: 'text',
        label: vm.$t('header.agencyListTable.agencyCode'),
        text: null,
        rule: `required|noSpace|pattern:agencyCode`,
        key: 'agencyCode',
      },
      {
        value: null,
        targetName: 'agencyName',
        type: 'text',
        label: vm.$t('header.agencyListTable.agencyName'),
        text: null,
        rule: `required|max:${MaxLength.Default}`,
        key: 'agencyName',
      },
    ],

    // 代理店登録データ
    createAgencyTargets: [],

    // 代理店登録ダイアログが表示されているかどうか
    showCreateAgencyDialog: false,

    // 代理店登録ボタンが有効であるかどうか
    isAgencyRegisterButtonEnabled: false,

    // 代理店一括登録ボタンが有効であるかどうか
    isAgencyBulkRegisterButtonEnabled: false,

    // 代理店一括更新ボタンが有効であるかどうか
    isAgencyBulkEditButtonEnabled: false,

    // ローディング中であるかどうか
    isLoading: true,

    // 部担コード対象営業店情報
    salesCodeDetails: [],

    // 代理店コード一覧
    agencyCodeList: [],

    // 登録なのかどうか
    isRegister: null,

    // 一括登録代理店一覧
    bulkRegisterAgencyList: {},

    // 一括更新代理店一覧
    bulkUpdateAgencyList: {},
  }),
  watch: {
    options: {
      handler() {
        this.fetchOffices();
      },
      deep: true,
    },
  },
  mounted() {
    // ページ遷移共通: ナビゲーション一覧更新
    this.$emit('updateNavigation');

    // 代理店登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isAgencyRegisterButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'agencyRegister')
    );

    // 代理店一括登録ボタンの有効状態をセットする
    this.$set(
      this,
      'isAgencyBulkRegisterButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'agencyBulkRegister')
    );

    // 代理店一括更新ボタンの有効状態をセットする
    this.$set(
      this,
      'isAgencyBulkEditButtonEnabled',
      Role.isButtonEnabled(this.userDetail(), 'agencyBulkEdit')
    );
  },
  methods: {
    ...mapGetters('user', ['userDetail']),
    ...mapActions('ui', ['setLoading']),

    // オプションの取得
    getOption() {
      return {
        perPage: this.options.itemsPerPage,
        pageNumber: this.options.page,
        ...(this.options.sortBy.length > 0 && {
          sortKey: this.sortKey[this.options.sortBy[0]],
        }),
        ...(this.options.sortDesc.length > 0 && {
          isDesc: this.options.sortDesc[0],
        }),
      };
    },

    // 代理店取得条件の取得
    getAgencyCondtion(agencySearchCondition) {
      return {
        ...agencySearchCondition,
      };
    },

    // 代理店一覧の取得
    async fetchOffices() {
      // 代理店一覧の取得
      const agencies = await getAgencies(
        this.getOption(),
        this.getAgencyCondtion(this.agencySearchCondition)
      ).catch(() => {
        // 空データをセットする
        this.$set(this, 'records', [{}]);
      });

      this.$set(this, 'isLoading', false);

      // 代理店一覧が取得できなかった場合、以降の処理を中止する
      if (!agencies) return;

      // 表示値のマッピング
      const records = this.mapAgencies(agencies);
      // 代理店一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', agencies.count);
    },

    // 代理店データのマッピング
    mapAgencies(agencies) {
      return agencies.rows.map(agency => ({
        detail: this.$t('button.view'),
        id: agency.id,
        agencyCode: agency.agencyCode,
        agencyName: agency.agencyName,
        salesCode: agency.sale ? agency.sale.salesCode : null,
        salesDepartmentName: agency.sale
          ? agency.sale.salesDepartmentName
          : null,
        salesSectionName: agency.sale ? agency.sale.salesSectionName : null,
      }));
    },

    // 代理店登録キャンセル
    cancelCreateAgency() {
      this.$set(this, 'showCreateAgencyDialog', false);
    },

    // 代理店登録
    async createAgency(item) {
      const agency = {
        agencyCode: item.agencyCode,
        agencyName: item.agencyName,
      };
      const result = await postAgency(agency).catch(error => {
        let message;
        switch (error.code) {
          case ErrorCode.DuplicateAgencyCode:
            message = this.$t('error.duplicateAgencyCode', {
              agencyCode: item.agencyCode,
            });
            break;
          default:
            message = this.$t('error.agencyRegisterBadRequest');
        }
        this.$refs.errorDialog.open(
          this.$t('title.agency.agencyRegisterError'),
          message
        );
      });

      // 代理店が登録できなかった場合、以降の処理を中止する
      if (!result) return;

      await this.fetchOffices();

      this.$set(this, 'showCreateAgencyDialog', false);

      // 代理店詳細画面へ遷移する
      this.$router.push({
        path: `/agency_detail/${result.id}`,
      });
    },

    // 代理店登録ボタン押下時
    onClickAgencyRegisterButton() {
      const targets = JSON.parse(
        JSON.stringify(this.createAgencyDefaultTargets)
      );
      const createAgencyTargets = targets.map(target => {
        return target;
      });

      this.$set(this, 'createAgencyTargets', createAgencyTargets);
      this.$set(this, 'showCreateAgencyDialog', true);
    },

    // 代理店検索ボタン押下時
    onClickAgencySearchButton() {
      this.$refs.agencySearchDialog.open();
    },

    // 代理店検索成功時
    async onSuccessSearchAgency(condition) {
      // 代理店一覧の取得
      const agencies = await getAgencies(
        this.getOption(),
        this.getAgencyCondtion(condition)
      ).catch(() => {
        this.$refs.agencySearchDialog.onErrorSearch();
      });

      // 代理店一覧が取得できなかった場合、以降の処理を中止する
      if (!agencies) return;

      // 代理店が0件の場合、以降の処理を中止する
      if (agencies.count === 0) {
        this.$refs.agencySearchDialog.onErrorSearch();
        return;
      }

      // 代理店一覧検索条件の保存
      this.$set(this, 'agencySearchCondition', condition);

      // ページングの初期化
      this.$set(this.options, 'page', agencyListDisplayDefault.page);

      // 表示値のマッピング
      const records = this.mapAgencies(agencies);
      // 代理店一覧表示の更新
      this.$set(this, 'records', records);
      this.$set(this, 'total', agencies.count);

      this.$refs.agencySearchDialog.close();
    },

    // 代理店検索条件のクリア
    onClearAgencySearchCondition() {
      this.$set(this, 'agencySearchCondition', {});
    },
    // 一括登録ボタン押下時
    async onClickAgencyBulkRegisterButton() {
      // 営業店一覧の取得
      this.$set(this, 'salesCodeDetails', await getSalesCodeDetails());
      this.$set(this, 'agencyCodeList', await getAgencyCodeList());
      this.$set(this, 'isRegister', true);
      this.$refs.csvFileSelectDialog.open();
    },
    // CSV読み込み成功
    onSuccessCsvImport(csv, err) {
      if (this.isRegister) {
        this.onSuccessBulkRegisterCsvImport(csv, err);
      } else {
        this.onSuccessBulkUpdateCsvImport(csv, err);
      }
    },
    // 一括登録用CSV読み込み成功
    async onSuccessBulkRegisterCsvImport(csv, err) {
      if (err.length > 0) {
        this.$refs.validateErrorDialog.open(
          this.$t('title.agency.csvParseError'),
          this.$t('error.csvParseError'),
          err
        );
      } else {
        this.$set(this, 'bulkRegisterAgencyList', csv);
        this.$refs.bulkRegisterListDialog.open(
          this.$t('title.agency.agencyBulkRegisterConfirm'),
          this.$t('description.agencyBulkRegisterConfirm'),
          AgencyConfirmTableHeader,
          csv,
          this.itemsPerPageOptions
        );
      }

      this.$refs.csvFileSelectDialog.close();
    },
    // 一括登録
    async onBulkRegisterAgency() {
      this.setLoading(true);

      const result = await postBulkRegisterAgency(
        this.bulkRegisterAgencyList
      ).catch(err => {
        console.log(err);
      });
      this.setLoading(false);

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('success.created')
        );
        await this.fetchOffices();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.createResult'),
          this.$t('error.createFailed')
        );
      }
      this.$refs.bulkRegisterListDialog.close();
    },
    // 一括更新ボタン押下時
    async onClickAgencyBulkUpdateButton() {
      this.$set(this, 'salesCodeDetails', await getSalesCodeDetails());
      this.$set(this, 'agencyCodeList', await getAgencyCodeList());
      this.$set(this, 'isRegister', false);

      this.$refs.csvFileSelectDialog.open();
    },
    // 一括更新用CSV読み込み成功
    async onSuccessBulkUpdateCsvImport(csv, err) {
      this.setLoading(true);

      if (err.length > 0) {
        this.$refs.validateErrorDialog.open(
          this.$t('title.agency.csvParseError'),
          this.$t('error.csvParseError'),
          err
        );
      } else {
        this.$set(this, 'bulkUpdateAgencyList', csv);
        const targetAgencyCode = {
          agencyCode: [],
        };
        csv.forEach(function(agency) {
          targetAgencyCode.agencyCode.push(agency.agencyCode);
        });
        const targetAgencyList = await getUpdateAgencyList();
        // 更新箇所特定
        const newAgency = csv.map(agency => {
          const oldAgency = targetAgencyList.find(
            target => target.agencyCode === agency.agencyCode
          );
          const oldSalesCode = oldAgency.sale ? oldAgency.sale.salesCode : '';
          const oldSalesDepartmentName = oldAgency.sale
            ? oldAgency.sale.salesDepartmentName
            : '';
          const oldSalesSectionName = oldAgency.sale
            ? oldAgency.sale.salesSectionName
            : '';
          const newAgency = {
            agencyCode: {
              value: agency.agencyCode,
              class: 'text-center v-data-table__divider',
            },
            agencyName:
              oldAgency.agencyName === agency.agencyName
                ? {
                    value: agency.agencyName,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value: oldAgency.agencyName + '→' + agency.agencyName,
                    class: 'text-center v-data-table__divider update',
                  },
            salesCode:
              oldSalesCode === agency.salesCode
                ? {
                    value: agency.salesCode,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value: oldSalesCode + '→' + agency.salesCode,
                    class: 'text-center v-data-table__divider update',
                  },
            salesDepartmentName:
              oldSalesDepartmentName === agency.salesDepartmentName
                ? {
                    value: agency.salesDepartmentName,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value:
                      oldSalesDepartmentName + '→' + agency.salesDepartmentName,
                    class: 'text-center v-data-table__divider update',
                  },
            salesSectionName:
              oldSalesSectionName === agency.salesSectionName
                ? {
                    value: agency.salesSectionName,
                    class: 'text-center v-data-table__divider',
                  }
                : {
                    value: oldSalesSectionName + '→' + agency.salesSectionName,
                    class: 'text-center v-data-table__divider update',
                  },
          };
          return newAgency;
        });

        this.$refs.bulkUpdateListDialog.open(
          this.$t('title.agency.agencyBulkUpdateConfirm'),
          this.$t('description.agencyBulkUpdateConfirm'),
          AgencyConfirmTableHeader,
          newAgency,
          this.itemsPerPageOptions
        );
      }
      this.setLoading(false);
      this.$refs.csvFileSelectDialog.close();
    },
    // 一括更新
    async onBulkUpdateAgency() {
      this.setLoading(true);

      const result = await putBulkUpdateAgency(this.bulkUpdateAgencyList).catch(
        err => {
          console.log(err);
        }
      );
      this.setLoading(false);

      if (result) {
        this.$refs.completedDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('success.updated')
        );
        await this.fetchOffices();
      } else {
        this.$refs.errorDialog.open(
          this.$t('title.agency.updateResult'),
          this.$t('error.updateFailed')
        );
      }
      this.$refs.bulkUpdateListDialog.close();
    },
  },
};
</script>
<style>
.v_data_table_fill_width {
  width: 100%;
}
thead.v-data-table-header {
  background-color: #ddebf7;
}
thead.v-data-table-header > tr > th {
  font-size: 14px !important;
  text-align: center !important;
  padding: 0;
  white-space: pre-wrap;
}
.v_footer_justify_evenly {
  justify-content: space-evenly;
}
.v-data-table-header__icon {
  /* テーブルのソートアイコンを常に表示 */
  opacity: 1 !important;
  color: rgba(0, 0, 0, 0.2) !important;
}
th.active > .v-data-table-header__icon {
  color: rgba(0, 0, 0, 1) !important;
}
.v-data-table td {
  word-break: break-all;
}
/* 1ページあたりの行数を IE 11 でも表示させる */
.v-data-footer__select .v-select {
  width: 54px !important;
}
.v-data-table {
  overflow: hidden;
}
</style>

<template>
  <Dialog ref="dialog" :title="title" :color="'error'" width="500px">
    <v-container>
      <div class="text-center" style="margin: 0 auto;">
        <p>{{ message }}</p>
      </div>
    </v-container>
    <v-container
      v-if="errorMessageList.length > 0"
      id="scroll-target"
      class="overflow-y-auto mx-auto"
      style="max-height: 30vh; max-width: 90%; border: #ddd 3px solid; "
      wrap
    >
      <v-row v-for="(text, index) in errorMessageList" :key="index" class="my-0">
        {{ text }}
      </v-row>
    </v-container>
    <template v-slot:footer>
      <v-btn width="25%" class="back" dark @click="close()">
        {{ $t('button.close') }}
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import Dialog from '@/components/organisms/agency/Dialog';

export default {
  components: { Dialog },
  data() {
    return {
      title: '',
      message: '',
      errorMessageList: [],
    };
  },
  methods: {
    open(title, message, errorMessageList) {
      (this.title = title),
      (this.message = message),
      (this.errorMessageList = errorMessageList),
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
  },
};
</script>

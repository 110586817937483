<template>
  <Dialog ref="dialog" :title="$t('title.agency.agencySearch')" :color="'next'">
    <v-container class="pt-0 px-8">
      <v-form lazy-validation ref="form" @submit.prevent>
        <v-row>
          <v-col>
            <v-chip
              ripple
              class="font-weight-black float-right"
              @click="onClickClearButton()"
              >{{ $t('button.clear') }}<v-icon>mdi-delete</v-icon></v-chip
            >
          </v-col>
        </v-row>

        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyCode"
              :label="$t('header.agencyMasterTable.agencyCode')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.agencyName"
              :label="$t('header.agencyMasterTable.agencyName')"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col :class="conditionStyle">
            <v-text-field
              v-model="condition.salesCode"
              :label="$t('header.salesMasterTable.salesCode')"
            ></v-text-field>
          </v-col>
        </v-row>
      </v-form>
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn width="33%" class="next" dark @click="onClickSearchButton()">
        {{ $t('button.search') }}
      </v-btn>
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';

export default {
  components: { Dialog, ErrorDialog },
  data() {
    return {
      // 代理店検索項目
      condition: {
        agencyCode: '',
        agencyName: '',
        salesCode: '',
      },

      // 編集フォームのスタイル指定
      conditionStyle: ['my-0', 'py-0'],
    };
  },
  methods: {
    ...mapGetters('user', ['userDetail']),

    open() {
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      this.condition.agencyCode = '';
      this.condition.agencyName = '';
      this.condition.salesCode = '';
    },

    // クリアボタンの押下時
    onClickClearButton() {
      this.init();
      this.$emit('onClear');
    },

    // 検索ボタンの押下時
    onClickSearchButton() {
      // 検索項目
      const condition = {
        ...(this.condition.agencyCode && {
          agencyCode: this.condition.agencyCode,
        }),
        ...(this.condition.agencyName && {
          agencyName: this.condition.agencyName,
        }),
        ...(this.condition.salesCode && {
          salesCode: this.condition.salesCode,
        }),
      };
      this.$emit('onSuccess', condition);
    },

    // 検索エラー時
    onErrorSearch() {
      this.$refs.errorDialog.open(
        this.$t('title.agency.agencySearchError'),
        this.$t('error.agencyListNotFound')
      );
    },
  },
};
</script>

<template>
  <Dialog
    ref="dialog"
    :title="$t('title.agency.csvFileSelect')"
    :width="'35%'"
    :color="'next'"
  >
    <v-container>
      <input
        type="file"
        @change="loadCsvFile"
        ref="file"
        class="px-4"
        style="width:100%;"
        accept=".csv"
      />
    </v-container>

    <template v-slot:footer>
      <v-btn width="33%" class="back" dark @click="close()">{{
        $t('button.cancel')
      }}</v-btn>
      <v-btn
        width="33%"
        class="next"
        :dark="isImportButtonEnabled"
        :disabled="!isImportButtonEnabled"
        @click="onClickImportButton()"
        >{{ $t('button.import') }}</v-btn
      >
    </template>
    <ErrorDialog ref="errorDialog"></ErrorDialog>
  </Dialog>
</template>

<script>
import moment from 'moment';
import {
  readCsv,
  getCsvDuplicateValues,
} from '@/lib/csv';
import Dialog from '@/components/organisms/agency/Dialog';
import ErrorDialog from '@/components/organisms/agency/ErrorDialog';
import validation from '@/lib/validation';
import { AgencyBulkRegisterCsv, MaxCsvImportRowCount } from '@/lib/const';
import { mapActions } from 'vuex';

export default {
  components: { Dialog, ErrorDialog },
  props: {
    salesCodeDetails: Array,
    agencyCodeList: Array,
    isRegister: Boolean,
  },
  data() {
    return {
      // 選択されたファイル
      file: '',

      // インポートボタンが有効であるかどうか
      isImportButtonEnabled: false,
    };
  },
  watch: {
    file: {
      handler(file) {
        // ファイル選択時のみインポートボタンを有効化する
        this.$set(this, 'isImportButtonEnabled', file ? true : false);
      },
      deep: true,
    },
  },
  methods: {
    ...mapActions('ui', ['setLoading']),
    open() {
      this.init();
      this.$refs.dialog.open();
    },
    close() {
      this.$refs.dialog.close();
    },
    init() {
      if (this.$refs.file) {
        // ファイル選択を初期化
        this.$refs.file.value = '';
      }
      // 読み込み済みのファイルデータを初期化
      this.$set(this, 'file', '');
    },
    loadCsvFile(e) {
      this.$set(this, 'file', e.target.files[0]);
    },
    parseCsv(file) {
      readCsv(file, (fileData, error) => {
        // ファイルが読み込めなかった場合
        if (error) {
          return this.onErrorReadCsv(this.$t('error.invalidFile'));
        }
        // データ解析
        try {
          // 一括更新の上限数を超えた場合
          if (
            !this.isRegister &&
            fileData.length - 1 > MaxCsvImportRowCount.Default
          ) {
            throw this.$t('error.invalidCsvImportMaxRow', {
              maxRowCount: MaxCsvImportRowCount.Default,
            });
          }

          const importDate = moment().format('YYYY-MM-DD HH:mm:ss');

          const headers = [];
          fileData[0].forEach((header, index) => {
            // CSV のヘッダー列が形式と一致しない場合
            if (header.trim() !== AgencyBulkRegisterCsv.columns[index].name) {
              throw this.$t('error.invalidCsvColumns');
            }
            headers.push(AgencyBulkRegisterCsv.columns[index].name);
          });

          // 代理店コードの重複値群
          const agencyCodes = getCsvDuplicateValues('agencyCode', fileData);

          const rows = [];
          let err = [];
          for (let index = 1; index < fileData.length; index++) {
            const recode = fileData[index];
            const row = {};
            for (
              let headerIndex = 0;
              headerIndex < headers.length;
              headerIndex++
            ) {
              const header = headers[headerIndex];
              row[header] = this.convertValue(
                recode[headerIndex].trim(),
                AgencyBulkRegisterCsv.columns[[headerIndex]]
              );

              const rule =
                validation[AgencyBulkRegisterCsv.columns[[headerIndex]].rule];
              let hasError;
              // バリデーションチェック
              for (let ruleIndex = 0; ruleIndex < rule.length; ruleIndex++) {
                const validate = rule[ruleIndex](row[header]);
                if (validate !== true) {
                  err.push(
                    index +
                      this.$t('label.rows') +
                      '：' +
                      AgencyBulkRegisterCsv.headers[header] +
                      '：' +
                      validate
                  );
                  hasError = true;
                  // 1セルにつきエラーは一つまでに抑制
                  break;
                }
              }
              // 既にエラーが存在する場合、以降のチェックを実施しない
              if (hasError) continue;

              // 代理店コード存在確認
              if (header === 'agencyCode') {
                if (this.isRegister) {
                  if (this.agencyCodeList.includes(row[header])) {
                    err.push(
                      index +
                        this.$t('label.rows') +
                        '：' +
                        AgencyBulkRegisterCsv.headers[header] +
                        '：' +
                        this.$t('error.existCode', {
                          code: recode[headerIndex],
                        })
                    );
                    continue;
                  }
                } else {
                  if (!this.agencyCodeList.includes(row[header])) {
                    err.push(
                      index +
                        this.$t('label.rows') +
                        '：' +
                        AgencyBulkRegisterCsv.headers[header] +
                        '：' +
                        this.$t('error.notExistCode', {
                          code: recode[headerIndex],
                        })
                    );
                    continue;
                  }
                }

                // 重複チェック
                if (
                  agencyCodes.filter(agencyCode => agencyCode === row[header])
                    .length > 1
                ) {
                  err.push(
                    index +
                      this.$t('label.rows') +
                      '：' +
                      AgencyBulkRegisterCsv.headers[header] +
                      '：' +
                      this.$t('error.duplicateValue', {
                        value: recode[headerIndex],
                      })
                  );
                  continue;
                }
              }

              // 営業店コード存在確認
              if (header === 'salesCode') {
                const sales = this.salesCodeDetails[row[header]];
                if (sales) {
                  row.salesId = sales.selesId;
                  row.salesCode = sales.salesCode;
                  row.salesDepartmentName = sales.salesDepartmentName;
                  row.salesSectionName = sales.salesSectionName;
                } else {
                  err.push(
                    index +
                      this.$t('label.rows') +
                      '：' +
                      AgencyBulkRegisterCsv.headers[header] +
                      '：' +
                      this.$t('error.notExistCode', {
                        code: recode[headerIndex],
                      })
                  );
                  continue;
                }
              }
            }
            rows.push(row);
          }
          this.setLoading(false);
          this.$emit('onSuccess', rows, err);

          // データ形式に誤りがある場合
        } catch (message) {
          this.setLoading(false);
          this.onErrorImportCsv(message);
        }
      });
    },
    convertValue(input, column) {
      let value;
      switch (column.type) {
        case 'number': {
          value = isNaN(input) ? parseInt(input) : input;
          break;
        }
        case 'date': {
          value = moment(input).format(column.format);
          break;
        }
        case 'text':
        default:
          value = input;
      }
      return value;
    },
    onClickImportButton() {
      this.setLoading(true);
      this.parseCsv(this.file);
    },
    onErrorReadCsv(message) {
      this.setLoading(false);
      this.$refs.errorDialog.open(
        this.$t('title.agency.csvReadError'),
        message
      );

      this.close();
    },
    onErrorImportCsv(message) {
      this.$refs.errorDialog.open(
        this.$t('title.agency.csvParseError'),
        message
      );
    },
  },
};
</script>
